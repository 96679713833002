
import { Vue, Component, Watch } from 'vue-property-decorator';
import {ContaCorrenteService} from '@/core/services/financeiro';
import {ContaCorrente} from '@/core/models/financeiro';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';

@Component
export default class ListaContaCorrente extends mixins(Vue,ListPage) {
  public service = new ContaCorrenteService();
  public item = new ContaCorrente();

  titulo: string = 'Conta Corrente';
  subTitulo: string = 'Tipos de Contas correntes cadastrados no Sistema';

  loading: boolean = false;
  dialogCadastro: boolean = false;

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Agência', value: 'agenciaNumeroDigito', sortable: false},
    { text: 'Conta', value: 'contaNumeroDigito', sortable: false}
  ];

  options: any = {
    itemsPerPage: 15
  };

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', 'id, nome, agenciaNumeroDigito, contaNumeroDigito', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }) 
    .finally(() => (this.loading = false));
  }
    
  Novo(){
    this.item = new ContaCorrente;
    this.dialogCadastro = true;
  }
  mounted(){
    //this.Atualizar();
  }
}

